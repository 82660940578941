import { React, useState } from "react";
import { Plus, ArrowRight, Merge, Sparkles } from "lucide-react";
import { DownloadButton } from "../../Utils/DownloadButton.jsx";
import { Download } from "lucide-react";
import FileUploader from "../FileUploader.jsx";
import DemoImage from "../../../assets/images/demoImage.svg";
import { Stage, Layer, Image, Transformer } from "react-konva";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { generateUniqueFilename } from "../../../utils/utils";

const EditableImage = ({ backgroundSrc, modelSrc, setStageRef }) => {
  const [isSelected, setIsSelected] = useState(false);
  const [containerSize] = useState({ width: 288, height: 340 });
  const imageRef = useRef(null);
  const trRef = useRef(null);
  const stageRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [modelImage, setModelImage] = useState(null);

  const loadImage = async (src) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.crossOrigin = "anonymous";
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
    });
  };

  useEffect(() => {
    if (backgroundSrc?.newImageUrl) {
      loadImage(backgroundSrc.newImageUrl)
        .then(setBackgroundImage)
        .catch((err) => console.error(err));
    }

    if (modelSrc) {
      loadImage(modelSrc)
        .then(setModelImage)
        .catch((err) => console.error(err));
    }

    if (setStageRef) {
      setStageRef(stageRef.current);
    }
  }, [backgroundSrc, modelSrc, setStageRef]);

  useEffect(() => {
    if (imageRef.current && trRef.current) {
      if (isSelected) {
        trRef.current.nodes([imageRef.current]);
      } else {
        trRef.current.nodes([]);
      }
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const handleDeselect = (e) => {
    if (e.target === stageRef.current) {
      setIsSelected(false);
    }
  };

  const handleDownload = () => {
    if (!stageRef.current) {
      console.error("Stage reference is not available for download.");
      return;
    }
    setIsSelected(false);
    setTimeout(() => {
      const dataURL = stageRef.current.toDataURL();
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = generateUniqueFilename("png");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 1000);
  };

  return (
    <>
      <div className="relative w-72 overflow-hidden border-3 border-dotted border-gray-500 rounded-lg">
        <div
          style={{
            width: `${containerSize.width}px`,
            height: `${containerSize.height}px`,
          }}
        >
          <Stage
            ref={stageRef}
            width={containerSize.width}
            height={containerSize.height}
            onMouseDown={handleDeselect}
            style={{ backgroundColor: "#0e3b4b" }}
          >
            <Layer>
              {backgroundImage && (
                <Image
                  image={backgroundImage}
                  x={0}
                  y={0}
                  width={containerSize.width}
                  height={containerSize.height}
                  onClick={(e) => {
                    e.cancelBubble = false;
                    setIsSelected(false);
                  }}
                />
              )}
              {modelImage && (
                <Image
                  ref={imageRef}
                  image={modelImage}
                  draggable
                  x={containerSize.width * 0.1}
                  y={containerSize.height * 0.1}
                  width={
                    (containerSize.height * 0.8 * modelImage.width) /
                    modelImage.height
                  }
                  height={containerSize.height * 0.8}
                  onClick={(e) => {
                    e.cancelBubble = true;
                    setIsSelected(true);
                  }}
                  onTap={(e) => {
                    e.cancelBubble = true;
                    setIsSelected(true);
                  }}
                />
              )}
              <Transformer
                ref={trRef}
                anchorSize={6}
                enabledAnchors={[
                  "top-left",
                  "top-right",
                  "bottom-left",
                  "bottom-right",
                ]}
                rotateEnabled={false}
                boundBoxFunc={(oldBox, newBox) => {
                  if (newBox.width < 30 || newBox.height < 30) {
                    return oldBox;
                  }
                  return newBox;
                }}
              />
            </Layer>
          </Stage>
        </div>
      </div>
      <div className="mt-4">
        <button
          onClick={handleDownload}
          className="px-4 py-2 ml-16 bg-blue-500 text-white rounded-lg"
        >
          <div className="flex items-center gap-2">
            <Merge /> Merge Image
          </div>
        </button>
      </div>
    </>
  );
};

const ImageProcessor = ({
  title,
  images,
  modelImages,
  uploadTitle,
  onFaceSelect,
  onModelSelect,
  onFaceUpload,
  onModelUpload,
  onRun,
  selectedFace,
  selectedModel,
  appliedFilter,
  isRunning,
  isDownloadReady,
  Header,
  type_run,
}) => {
  const [showAllImages, setShowAllImages] = useState(false);
  const [showAllModelImages, setShowAllModelImages] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [faceImage, setFaceImage] = useState({});
  const [modelImage, setModelImage] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentModelImageIndex, setCurrentModelImageIndex] = useState(0);

  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);
  const stageRef = useRef();

  const handleFaceImageSelect = (img) => {
    onFaceSelect(img);
    setFaceImage({
      newImage: img,
      newImageUrl: img,
    });
    setShowAllImages(false);
  };

  const handleModelImageSelect = (img) => {
    onModelSelect(img);
    setModelImage({
      newImage: img,
      newImageUrl: img,
    });
    setShowAllModelImages(false);
  };

  const handleShowMoreFaces = () => {
    setShowAllImages(true);
  };

  const handleShowMoreModels = () => {
    setShowAllModelImages(true);
  };

  const handleFaceImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setFaceImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onFaceSelect(newImageUrl);
    }
  };

  const handleModelImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setModelImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onModelSelect(newImageUrl);
    }
  };

  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const handleFaceReset = () => {
    setFaceImage({});
  };

  const handleModelReset = () => {
    setModelImage({});
  };

  return (
    <div
      className={`${isSidebarOpen ? "ml-48" : ""} bg-[#222226] transition-all duration-200 px-8 p-4 rounded-xl`}
    >
      <h1 className="text-3xl mb-2 text-center text-[#1D3A72]">{Header}</h1>
      <div className="flex flex-col lg:flex-row items-center gap-4">
        {/* Left side - Face Images */}
        <div className="w-full lg:w-1/3 rounded-lg p-4">
          <h2 className="text-lg text-white text-center mb-4">{title}</h2>
          <div className="aspect-square w-[300px] mx-auto mb-4">
            {faceImage.newImageUrl ? (
              <img
                src={faceImage.newImageUrl}
                alt="preview"
                className="w-full h-full object-contain rounded-lg"
              />
            ) : (
              <FileUploader handleImageUpload={handleFaceImageUpload} />
            )}
          </div>
          <div className="flex justify-center mb-4">
            <div className="flex items-center gap-2 overflow-x-auto">
              {images
                .slice(currentImageIndex, currentImageIndex + 3)
                .map((img, index) => (
                  <div key={index} className="relative w-20 h-20">
                    {img ? (
                      <img
                        src={img}
                        className={`w-20 h-20 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                          selectedFace === img
                            ? "border-2 border-yellow-400"
                            : ""
                        }`}
                        alt={`Filter ${currentImageIndex + index + 1}`}
                        onClick={() => handleFaceImageSelect(img)}
                        loading="lazy"
                        style={{
                          filter: "blur(2px)",
                          transition: "filter 0.3s ease-in-out",
                        }}
                        onLoad={(e) => (e.target.style.filter = "none")}
                      />
                    ) : (
                      <div className="animate-pulse bg-gray-700 w-full h-full rounded-lg"></div>
                    )}
                  </div>
                ))}
              <button
                className="w-20 h-20 p-2 border text-gray-400 text-sm rounded-lg hover:bg-[#282828] transition-colors flex items-center justify-center"
                onClick={handleShowMoreFaces}
              >
                <span className="text-sm">Show More</span>
              </button>
            </div>
          </div>
          {faceImage.newImageUrl && (
            <div className="flex justify-center">
              <button
                onClick={handleFaceReset}
                className="bg-red-500 px-12 py-2 rounded-lg text-white uppercase"
              >
                Reset
              </button>
            </div>
          )}
        </div>

        {/* Center Icons */}
        <div className="flex flex-col items-center justify-center">
          <Plus className="text-gray-500 w-12 h-12 mb-2" />
        </div>

        <div className="w-full lg:w-2/3 rounded-lg">
          <div className="flex flex-col md:flex-row gap-4">
            {/* Model Image Upload Section */}
            <div className="w-full md:w-1/2 rounded-lg p-4">
              <h2 className="text-lg text-white text-center mb-4">
                {uploadTitle}
              </h2>
              <div className="aspect-square w-[300px] mx-auto mb-4">
                {modelImage.newImageUrl ? (
                  <img
                    src={modelImage.newImageUrl}
                    alt="preview"
                    className="w-full h-full object-contain rounded-lg"
                  />
                ) : (
                  <FileUploader handleImageUpload={handleModelImageUpload} />
                )}
              </div>
              <div className="flex justify-center mb-4">
                <div className="flex items-center gap-2 overflow-x-auto">
                  {Array.from({ length: 3 }).map((_, index) => {
                    const img = modelImages[currentModelImageIndex + index];
                    return (
                      <div key={index} className="relative w-20 h-20">
                        {img ? (
                          <img
                            src={img}
                            className={`w-20 h-20 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                              selectedModel === img
                                ? "border-2 border-yellow-400"
                                : ""
                            }`}
                            alt={`Model ${currentModelImageIndex + index + 1}`}
                            onClick={() => handleModelImageSelect(img)}
                            loading="lazy"
                            style={{
                              filter: "blur(2px)",
                              transition: "filter 0.3s ease-in-out",
                            }}
                            onLoad={(e) => (e.target.style.filter = "none")}
                          />
                        ) : (
                          <div className="animate-pulse bg-gray-700 w-full h-full rounded-lg"></div>
                        )}
                      </div>
                    );
                  })}

                  <button
                    className="w-20 h-20 p-2 border text-gray-400 text-sm rounded-lg hover:bg-[#282828] transition-colors flex items-center justify-center"
                    onClick={handleShowMoreModels}
                  >
                    <span className="text-sm">Show More</span>
                  </button>
                </div>
              </div>
              {modelImage.newImageUrl && (
                <div className="flex justify-center">
                  <button
                    onClick={handleModelReset}
                    className="bg-red-500 px-12 py-2 rounded-lg text-white uppercase"
                  >
                    Reset
                  </button>
                </div>
              )}
            </div>

            <div className="flex items-center">
              <ArrowRight className="text-gray-500 w-12 h-12" />
            </div>

            {/* Processed Image Section */}
            <div className="w-full md:w-1/2 relative rounded-lg py-4">
              <h2 className="text-lg text-white text-center mb-4">
                Output Image
              </h2>
              <div className="aspect-square w-full  mb-4">
                {isRunning ? (
                  <div className="w-full h-full">
                    <div className="w-full h-full bg-[#333537] rounded-lg flex items-center justify-center">
                      <h1 className="text-xl text-yellow-400">
                        Generation in Process
                      </h1>
                    </div>
                    <div className="loader"></div>
                  </div>
                ) : appliedFilter ? (
                  <>
                    <EditableImage
                      backgroundSrc={faceImage}
                      modelSrc={appliedFilter}
                      setStageRef={(ref) => (stageRef.current = ref)}
                    />
                  </>
                ) : (
                  <div className=" bg-[#333537] p-4 h-full flex flex-col items-center justify-center rounded-lg">
                    <img src={DemoImage} className="w-44 h-44 mb-4" />
                    <h1 className="text-sm text-gray-500">
                      Nothing to see here yet!
                    </h1>
                  </div>
                )}
                <div className="mt-8 flex justify-center gap-8 absolute w-full">
                  <button
                    onClick={onRun}
                    disabled={
                      !selectedFace.blobImage ||
                      !selectedModel.blobImage ||
                      isRunning
                    }
                    className="  text-black bg-[#8AB4F8] py-3 px-8 rounded-md cursor-pointer hover:bg-[#5286d8] transition-colors flex gap-2 items-center justify-center"
                  >
                    <Sparkles size={16} />
                    <span className="text-sm">
                      {" "}
                      {isRunning ? "Generating..." : "Generate"}
                    </span>
                  </button>

                  {/* <div className=" ">
                                        <DownloadButton imageUrl={appliedFilter} />
                                    </div> */}
                </div>
              </div>

              {/* {isDownloadReady && (
               
              )} */}
            </div>
          </div>
        </div>

        {/* Fullscreen Image Modal */}
        {fullscreenImage && (
          <div
            onClick={closeFullscreenImage}
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          >
            <div className="relative w-full h-full max-w-4xl max-h-4xl">
              <img
                src={fullscreenImage}
                alt="fullscreen"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        )}

        {/* Pop-up for all face images */}
        {showAllImages && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-8 w-11/12 max-w-5xl max-h-[90vh] overflow-y-auto relative">
              <button
                className="absolute top-4 right-4 text-3xl font-bold text-gray-600 hover:text-gray-800"
                onClick={() => setShowAllImages(false)}
              >
                ×
              </button>
              <h3 className="text-2xl font-bold mb-6">Choose a Face Image</h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {images.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                      selectedFace === img
                        ? "ring-4 ring-purple-600"
                        : "ring-1 ring-gray-300 hover:ring-purple-400"
                    }`}
                    alt={`Filter ${index + 1}`}
                    onClick={() => handleFaceImageSelect(img)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Pop-up for all model images */}
        {showAllModelImages && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-8 w-11/12 max-w-5xl max-h-[90vh] overflow-y-auto relative">
              <button
                className="absolute top-4 right-4 text-3xl font-bold text-gray-600 hover:text-gray-800"
                onClick={() => setShowAllModelImages(false)}
              >
                ×
              </button>
              <h3 className="text-2xl font-bold mb-6">Choose a Model Image</h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {modelImages.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                      selectedModel === img
                        ? "ring-4 ring-purple-600"
                        : "ring-1 ring-gray-300 hover:ring-purple-400"
                    }`}
                    alt={`Model ${index + 1}`}
                    onClick={() => handleModelImageSelect(img)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Run Button */}
      {/* <div className="flex items-center justify-center mt-2">
        <button
          className={`w-full uppercase max-w-[400px] px-4 py-2 rounded-lg flex items-center justify-center text-lg font-semibold ${
            selectedFace.blobImage && selectedModel.blobImage
              ? "bg-[#E4AA0E] text-blue-900 hover:bg-yellow-500"
              : "bg-gray-300 text-gray-600 cursor-not-allowed"
          } transition-colors`}
          onClick={onRun}
          disabled={!selectedFace.blobImage || !selectedModel.blobImage || isRunning}
        >
          {isRunning ? "Processing..." : type_run}
        </button>
      </div> */}
    </div>
  );
};

export default ImageProcessor;
